import React from 'react'
import {Row, Col, Button, InputNumber} from 'antd';
import {calcTime} from '../../../common/global.ts'

const DefineTime = (props:any) => {
    const checkTime = (data:number|string) => {
        if (props?.onClick && data) props.onClick(calcTime(parseInt(`${data}`)));
    }
    return <Row gutter={[16, 16]}>
        <Col span={4}>
            <Button 
                className={props?.data === 2?'cell-Btn-checked':'cell-Btn'}
                onClick={() => checkTime(120)}>
                {`2M`}
            </Button>
        </Col>
        <Col span={4}>
            <Button
                className={props?.data === 5?'cell-Btn-checked':'cell-Btn'}
                onClick={() => checkTime(300)}>
                {`5M`}  
            </Button>
        </Col>
        <Col span={4}>
            <Button
                className={props?.data === 10?'cell-Btn-checked':'cell-Btn'}
                onClick={() => checkTime(600)}>
                {`10M`}
            </Button>
        </Col>
        <Col span={4}>
            <Button
                className={props?.data === 30?'cell-Btn-checked':'cell-Btn'}
                onClick={() => checkTime(1800)}>
                {`30M`}
            </Button>
        </Col>
        <Col span={8}>
            <InputNumber className='cell-input' 
                placeholder='0-1000'
                defaultValue={
                    !props?.data || [2, 5, 10, 30].includes(props?.data) ? null:props?.data
                }
                min={1} max={1000} addonAfter='M'
                onBlur={(e) => {
                    checkTime(parseInt(e?.target?.value)*60)
                }}
                onKeyDown={(e:any) => {
                    if (e?.keyCode !== 13 || !e?.target?.value) return;
                    checkTime(parseInt(e?.target?.value)*60)
                }}
            />
        </Col>
    </Row>
}

DefineTime.displayName = 'DefineTime';
export default DefineTime;